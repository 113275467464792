
$(function() {

    /** NAVIGATION **/
    $('.menu-toggler').click(function(e) {
        e.preventDefault();
        $('.nav').fadeIn();
        $('.menu-overlay').show();
        $('.second-tier').removeClass('transform');
        $('.third-tier').removeClass('transform');
        $('.has-submenu').removeClass('active');
        $('.has-submenu-two').removeClass('active');
    });

    $(".has-submenu").mouseenter(function(e) {
        e.stopPropagation();
        $(this).removeClass('active');
        $(this).addClass('active');
        $('.second-tier').removeClass('transform');
        $(this).find(".second-tier").addClass('transform');
    }).mouseleave(function() {
        $(this).removeClass('active');
    });

    $(".has-submenu-two").mouseenter(function(e) {
        e.stopPropagation();
        $(this).removeClass('active');
        $(this).addClass('active');
    }).mouseleave(function() {
        $(this).removeClass('active');
    });

    $(".second-tier").mouseleave(function(e) {
        e.stopPropagation();
        $(this).removeClass('transform');
        $(this).parent('.has-submenu').removeClass('active');
    }).mouseenter(function() {
        $('.transform').prev('.has-submenu').addClass('active');
    });

    $(".no-submenu").mouseenter(function() {
        $('.second-tier').removeClass('transform');
    });

    $('.first-tier a').on('click',function (){
        $('.has-submenu').removeClass('active');
    });
    $('.menu-toggler').on('click', function(){
        $('.nav').show();
    });
});
